<template>
  <div class="case">
    <div class="header">
      <div class="t1">
        海师已经成功帮助
        <div>
          <span v-for="(item, key) in casesum" :key="key">
            {{ item }}
          </span>
        </div>
        人完成出国服务
      </div>
      <div class="t2">
        <span></span>
        {{ title }}已成功为{{ cases.service_sum }}人实现{{gj}}留学愿望
        <span></span>
      </div>
    </div>
    <div class="body">
      <div class="tabs">
        <div
          class="topitem"
          :class="{ active: top == key }"
          v-for="(item, key) in cases.top"
          :key="key"
          @click="tab(key)"
        >
          <div class="topitem_box">
            <div>{{ key }}</div>
            <p>{{ item }}人成功考入{{gj}}排名{{ key }}学院</p>
          </div>
          <img src="./image/jiantou.png" alt="" />
        </div>
      </div>
      <div class="title">
        {{ title }}服务共{{ cases.service_sum }}条成功案例
      </div>
      <div class="body_cnt">
        <el-table :data="cases.list.list" style="width: 100%">
          <el-table-column label="姓名" align="center" width="140">
            <template slot-scope="scope">
              <a link  :href="scope.row.hc_link" target="_blank">
                <div class="table_info">
                  <img :src="scope.row.avatar" alt="" />
                  <span>{{ scope.row.name| - }}</span>
                </div>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="rank_decimal" align="center" label="院校排名" width="80">
            <template slot-scope="scope">
              <div>{{ scope.row.rank_decimal | - }}</div>
            </template>
          </el-table-column>
          <el-table-column label="申请学校" align="center" width="160">
            <template slot-scope="scope">
              <div>
                <p>{{ scope.row.new_AppliSchool| - }}</p>
                <span>{{ scope.row.new_AppliSchoolE| - }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="申请专业" align="center" width="160">
            <template slot-scope="scope">
              <div>
                <p>{{ scope.row.profession_name| - }}</p>
                <span>{{ scope.row.profession_enname_varchar| - }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="new_GraduateSchool"
            label="本科院校"
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.new_GraduateSchool | - }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="new_GraduateMajor"
            label="本科专业"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.new_GraduateMajor | - }}</div>
            </template>
          </el-table-column>
          <!-- GPA -->
          <el-table-column prop="gpa_decimal" label="GPA" width="100" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.gpa_decimal | - }}</div>
              <div>{{ scope.row.gpa_decimalValue | - }}</div>
            </template>
          </el-table-column>
          <!-- TOEFL/IELTS -->
          <el-table-column
            prop="toefl_decimal"
            label="TOEFL/IELTS"
            align="center"
            width="120"
            v-if="$cookie.get('source')=='meiyan'"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.toefl_decimal | - }}</div>
            </template>
          </el-table-column>
           <!-- TOEFL -->
          <el-table-column v-if="$cookie.get('source')=='riben'" prop="toefl_decimal" label="TOEFL" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.toefl_decimal | - }}</div>
            </template>
          </el-table-column>
           <!-- IELTS -->
          <el-table-column v-if="$cookie.get('source')=='yingguo'" prop="IELTS" label="IELTS" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.ielts | - }}</div>
            </template>
          </el-table-column>
          <!-- GRE -->
          <el-table-column v-if="$cookie.get('source')=='meiyan'" prop="gre_varchar" label="GRE" align="center" width="60">
            <template slot-scope="scope">
              <div>{{ scope.row.gre | - }}</div>
            </template>
          </el-table-column>
          <!-- GMAT -->
          <el-table-column v-if="$cookie.get('source')=='meiyan'" prop="gmat_varchar" label="GMAT" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.gmat | - }}</div>
            </template>
          </el-table-column>
          <!-- N1 -->
          <el-table-column v-if="$cookie.get('source')=='riben'" prop="n1" label="N1" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.n1 | - }}</div>
            </template>
          </el-table-column>
          <!-- N2 -->
          <el-table-column v-if="$cookie.get('source')=='riben'" prop="n2" label="N2" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.n2 | - }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="fenye">
          <div
            class="more"
            v-if="!this.$cookie.get('token')"
            @click="$store.state.login = true"
          >
            登录后可获取更多案例资源
          </div>
          <el-pagination
            v-else
            background
            layout="prev, pager, next"
            :page-size="limit"
            :total="cases.list.total"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCase } from "@/api/haishi";
export default {
  data() {
    return {
      casesum: [],
      cases: {
        case_sum: 88829,
        list: {},
        service_sum: 3179,
        top: {},
      },
      gj:'',//国家
      limit: 10, //条数
      page: 1, //页数
      top: "top3",
      service_id: 2, //类型ID
      title: "",
    };
  },
  created(){
    let gj = this.$cookie.get('source')
    console.log(gj)
    if(gj=='riben'){
      this.gj = '日本'
    }else if(gj=='yingguo'){
      this.gj = '英国'
    }else if(gj=='meiyan'){
      this.gj = '美国'
    }
  },
  mounted() {
    // console.log(this.$route.query)
    let query = this.$route.query;
    this.top = query.rank;
    this.service_id = query.service_id;
    this.title = query.title;
    // this.obj.num = this.obj.num.toString().split("");
    this.getList();
  },
  methods: {
    getList() {
      getCase({
        top: this.top,
        service_id: this.service_id,
        limit: this.limit,
        page: this.page,
      }).then((res) => {
        // console.log(res.data)
        this.casesum = res.data.case_sum.toString().split("");
        this.cases = res.data;
      });
    },
    tab(key) {
      this.top = key;
      this.page = 1;
      this.getList();
    },
    pageChange(e) {
      this.page = e;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.case {
  .header {
    height: 200px;
    margin-bottom: 20px;
    background: url("./image/header.png") no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .t1 {
      display: flex;
      margin-bottom: 26px;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      align-items: center;
      div {
        margin: 0 10px;
        span {
          display: inline-block;
          width: 33px;
          height: 42px;
          border-radius: 4px;
          border: 2px solid rgba(255, 255, 255, 0.5);
          font-size: 36px;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin: 0 2px;
        }
      }
    }
    .t2 {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      display: flex;
      align-items: center;
      span {
        margin: 0 32px;
        width: 120px;
        height: 1px;
        display: inline-block;
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .body {
    width: 1200px;
    background: #ffffff;
    border-radius: 8px;
    .tabs {
      display: flex;
      height: 100px;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;
      .topitem {
        flex: 1;
        border-bottom: 2px solid #e6a23b;
        cursor: pointer;
        background: white;
        transition: all 1s;
        position: relative;
        .topitem_box {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          height: 60px;
          margin-top: 17px;
          border-right: 1px solid #ededed;
          box-sizing: border-box;

          div {
            font-size: 24px;
            font-weight: bold;
            color: #999999;
            margin-bottom: 15px;
            text-transform: uppercase;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            color: #999999;
            text-transform: uppercase;
          }
        }
        &:last-child {
          .topitem_box {
            border: none;
          }
        }
        > img {
          height: 8px;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
      }
      .active {
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        position: relative;
        transition: all 1s;
        .topitem_box {
          border: none;
          div {
            color: #935d14;
          }
          p {
            color: #935d14;
          }
        }
        > img{
          display: block;
        }
      }
    }
    .title {
      padding: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .body_cnt {
      padding: 0 20px;
      box-sizing: border-box;
      /deep/ .el-table {
        a:link{
          color: #606266;
          text-decoration: none;
        }
        a:visited {
          color: #606266;
        }
        .el-table__header-wrapper {
          .el-table__header {
            tr {
              th {
                background: #f5f5f5;
                border: none;
              }
            }
          }
        }
      }
    }
    .table_info{
      display: flex;
      align-items: center;
      img{
        width: 50px;
        margin-right: 20px;
      }
    }
    .more {
      font-size: 16px;
      font-weight: 400;
      color: #1890ff;
      border-radius: 4px;
      border: 1px solid rgba(24, 144, 255, 0.3);
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}
</style>
