import request from '@/utils/axios'

// 列表条件
export function getCondition (params) {
  return request({
    url: '/api/hwds/hwdsList-conditions',
    method: 'get',
    params
  })
}

// 搜索
export function getSearch (params) {
  return request({
    url: '/api/hwds/hwdsList-key',
    method: 'get',
    params
  })
}
// 海师列表
export function getHsList (data) {
  return request({
    url: '/api/hwds/hwdsList',
    method: 'post',
    data
  })
}

//海师详情
export function getDetails (params) {
  return request({
    url: '/api/hwds/hwdsList-info',
    method: 'get',
    params
  })
}
//海师详情-海师推荐
export function getRecommend (params) {
  return request({
    url: '/api/hwds/hwdsList-recommend',
    method: 'get',
    params
  })
}
//海师详情-评价列表
export function getPjList (params) {
  return request({
    url: '/api/hwds/hwdsList-eva',
    method: 'get',
    params
  })
}
//预约海师-获取海师服务
export function getServices (data) {
  return request({
    url: '/api/overseasTeacher/getMentorServices',
    method: 'post',
    data
  })
}
//预约海师-获取合同信息
export function getContract (data) {
  return request({
    url: '/api/order/getUserContract',
    method: 'post',
    data
  })
}
//预约海师-提交还是预约
export function addOrder (data) {
  return request({
    url: '/api/order/addOrder',
    method: 'post',
    data
  })
}
//成功案例
export function getCase (params) {
  return request({
    url: '/api/case/case-list',
    method: 'get',
    params
  })
}
